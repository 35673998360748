@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-Black.ttf'); font-weight: 900; }
@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-Bold.ttf'); font-weight: 700; }
@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-Regular.ttf'); font-weight: 500; }
@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-Regular.ttf'); font-weight: 400; }
@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-Light.ttf'); font-weight: 300; }
@font-face { font-family: 'Muli'; src: url('/assets/fonts/Muli-ExtraLight.ttf'); font-weight: 100; }


html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgba(25, 41, 58, 1);
}

html, body, .MuiTypography-root, th, tr, caption, input, textarea {
    font-family: 'Muli' !important;
}

.MuiTypography-h4 {
    font-weight: 700 !important;
}

.MuiTypography-body1 {
    font-weight: 500 !important;
    font-size: 1.1rem !important;
}

.MuiTypography-body2 {
    font-weight: 500 !important;
    line-height: 1.8 !important;
    letter-spacing: 0.03em !important; 
}

.block {
  display:block !important;
}

.w-full {
  width: 100%;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.cursor:hover {
  cursor: pointer;
}

/* SCROLL BAR */

#root ::-webkit-scrollbar {
  width: 0.8em;
  height: 0.8em;
}

#root ::-webkit-scrollbar-track {
  background-color: rgb(43, 58, 82);
}

#root ::-webkit-scrollbar-thumb {
  background-color: #0e1826;
}

/* MARGINS */

.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-05 {
  margin: 5px !important;
}

.m-1 {
  margin: 10px !important;
}

.m-2 {
  margin: 20px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-05 {
  margin-left: 5px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-05 {
  margin-right: 5px !important;
}

.mr-1 {
  margin-right: 10px !important;
}

.mr-2 {
  margin-right: 20px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-05 {
  margin-top: 5px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-05 {
  margin-bottom: 5px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.ms-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ms-1 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.ms-2 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

/* PADDING */

.p-0 {
  padding: 0 !important;
}

.p-05 {
  padding: 5px !important;
}

.p-1 {
  padding: 10px !important;
}

.p-2 {
  padding: 20px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 10px !important;
}

.pl-2 {
  padding-left: 20px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 10px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.ps-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ps-1 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ps-2 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

a {
    text-decoration: none;
}

.autoSticky {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
}

/* COLORS */

.colorWhite {
  color: rgba(250, 250, 250, 1);
}

.colorError {
  color: rgba(223, 57, 57, 1);
}

.colorWarning, .colorWarning input, .colorWarning label {
  color: rgba(255, 160, 0, 1);
}

.colorSecondary {
  color: rgba(169, 190, 212, 1);
}

.colorLight, a {
  color: #8490c7;
}



/* FONTS */

.w-bolder {
  font-weight: 900 !important;
}

.w-bold {
  font-weight: 700 !important;
}

.w-medium {
  font-weight: 500 !important;
}

.w-light {
  font-weight: 300 !important;
}




/* React-Select */

.react-select {
  font-family: 'Muli';
  border: none;
}

.react-select__control {
  cursor: pointer !important;
  border: none !important;
}

.react-select-full-height .react-select__control {
  height: 100%;
}

.react-select__input,
.react-select__placeholder,
.react-select__single-value,
.react-select__menu,
.react-select__multi-value__label,
.react-select__multi-value {
  color: rgba(236, 255, 246, 1) !important;
}

.react-select__placeholder {
  opacity: 0.7;
}

.react-select__option:hover {
  cursor: pointer;
}

.react-select__menu {
  z-index: 5 !important;
}

.react-select__menu,
.react-select__control {
  background-color: rgba(25, 41, 58, 1) !important;
}

.react-select.darker .react-select__control,
.react-select.darker .react-select__option,
.react-select.darker .react-select__menu {
  background-color: rgba(12, 21, 33, 0.5) !important;
}

.react-select.dark .react-select__control,
.react-select.dark .react-select__option,
.react-select.dark .react-select__menu {
  background-color: rgba(54, 57, 64, 1) !important;
}

.react-select .react-select__option--is-focused,
.react-select.darker .react-select__option--is-focused,
.react-select__multi-value {
  background-color: rgba(35, 48, 68, 1) !important;
}

.react-select .react-select__option--is-selected,
.react-select.darker .react-select__option--is-selected,
.react-select.dark .react-select__option--is-selected {
  background-color: rgba(230, 230, 230, 1) !important;
  color: rgba(12, 21, 33, 1) !important;
}

.react-select__multi-value__remove:hover {
  background-color: #27539F !important;
  color: rgba(230, 230, 230, 1) !important;
}

.react-select-error .react-select__control {
  border: 2px solid rgba(223, 57, 57, 0.8) !important;
}

.react-select-error-message {
  color: rgba(223, 57, 57, 1);
  font-size: 0.75rem;
  margin: 0.5rem;
}

.react-select .select-thumbnail-image {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
}

.react-select .select-thumbnail-label {
  position: relative;
  bottom: 8px;
}


/* APP */

.navbar-logo {
  max-width: 140px;
}

.page {
  height: calc(100% - 64px);
  overflow-y: auto;
}

.shadow {
  box-shadow: 0 0px 30px 0 rgba(0, 0, 0, .3) !important;
}

.notify-list {
  width: 100%;
  max-width: 250px;
  top: 20px !important;
  z-index: 99999999 !important;
}

.notify-list .notify-item {
  position: relative !important;
  margin-bottom: 10px;
  width: 100%;
}

.notify-list .notify-content {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px;
  color: white;
}

.lowOpacity {
  opacity: 0.5;
}

.disabledInput {
  opacity: 0.3;
}

.disabledInput:hover {
  opacity: 1;
}

.MuiDivider-root {
  background-color: rgba(255, 255, 2555, 0.12) !important;
}

.fullscreenLoader {
  background-color: rgba(12, 21, 33, .9) !important;
}

.fullscreenLoader .loaderContent {
  width: 100% !important;
  max-width: 1600px !important;
  margin: auto !important;
  padding: 0 100px !important;
}

.fullscreenLoader .loaderContent span {
  text-transform: none;
  line-height: 1.8;
}

.fullscreenLoader .loading {
  margin: 0 auto 30px auto;
  display: block;
}

.centerProgress {
  margin: auto;
  display: block !important;
}

.MuiTab-root.Mui-selected span {
  color: #8490c7 !important;
}

.MuiTabs-indicator {
  background-color: #8490c7 !important;
}

.accordionInputs .MuiAccordionSummary-root {
  min-height: 30px !important;
}

.accordionInputs .MuiAccordionSummary-content {
  margin: 0 !important;
}

.accordionInputs .MuiAccordionDetails-root {
  display:block
}
